import React from "react";
import InputMask from "react-input-mask";

import {
  ContactContainer,
  FormContainer,
  FormTitle,
  FormDescription,
  Input,
  TextArea,
  ButtonContainer,
  SubmitButton,
  Side,
  MapContainer,
  Address,
  Map,
  Comunication,
  ComunicationTitle,
  Phones,
  PContainer,
  Phone,
  SocialMedia,
  SContainer,
  Facebook,
  Instagram,
  Whatsapp,
} from "./styles";

function Contato() {
  return (
    <ContactContainer>
      <FormContainer
        method="POST"
        action="https://getform.io/f/ffa78705-d620-4536-9ada-55cf30f6e151"
      >
        <FormTitle id="contato">Contate-nos</FormTitle>
        <FormDescription>
          Informe o curso desejado em (Assunto) e entraremos em contato para a
          efetivação da sua matrícula. <br /> * Obrigatório.
        </FormDescription>
        <Input placeholder="Nome *" type="text" name="name" required />
        <Input placeholder="Email *" type="email" name="email" required />
        <InputMask mask="(99) 99999-9999" type="text" name="phone">
          {inputProps => (
            <Input {...inputProps} placeholder="Telefone" type="tel" />
          )}
        </InputMask>
        <Input placeholder="Assunto *" type="text" name="subject" required />
        <TextArea placeholder="Digite sua mensagem aqui..." name="message" />
        <ButtonContainer>
          <SubmitButton type="submit">Enviar</SubmitButton>
        </ButtonContainer>
      </FormContainer>

      <Side>
        <Comunication>
          <ComunicationTitle>Canais de Comunicação</ComunicationTitle>
          <Phones>
            <PContainer>
              <Phone />
              (71) 98274-8695
            </PContainer>
            <PContainer>
              <Phone />
              (71) 99145-7220
            </PContainer>
          </Phones>
          <SocialMedia>
            <SContainer
              target="_blank"
              href="https://www.facebook.com/martins.treinamentos.3"
            >
              <Facebook />
              /martins.treinamentos.3
            </SContainer>
            <SContainer
              target="_blank"
              href="https://www.instagram.com/edson.martins.3517/"
            >
              <Instagram />
              @edson.martins.3517
            </SContainer>
            <SContainer
              target="_blank"
              href="https://api.whatsapp.com/send?phone=5571991457220"
            >
              <Whatsapp />
              Clique no Whatsapp!
            </SContainer>
          </SocialMedia>
        </Comunication>
        <MapContainer>
          <Map
            title="Mapa da Martins Treinamentos"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.980316649087!2d-38.32127678546873!3d-12.714706991024759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x71669e1a708696d%3A0x9b1b9862b1c829e7!2sMartins%20Treinamentos!5e0!3m2!1spt-BR!2sbr!4v1592488512042!5m2!1spt-BR!2sbr"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
          />
          <Address>
            <strong>Onde nos encontrar?</strong> Rua Comandante Avelar.
            Piaçaveira. CEP: 42809-457 - Camaçari - Bahia. Em frente ao Posto de
            Saúde.
          </Address>
        </MapContainer>
      </Side>
    </ContactContainer>
  );
}

export default Contato;
