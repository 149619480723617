import styled from "styled-components";
import { AiFillPhone } from "react-icons/ai";
import {
  FaFacebookSquare,
  FaWhatsappSquare,
  FaInstagramSquare,
} from "react-icons/fa";

const MEDIA = "@media only screen and (max-width: 900px)";

export const ContactContainer = styled.div`
  width: 100%;
  height: 1000px;
  background-color: #c90225;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  ${MEDIA} {
    height: auto;
    flex-direction: column;
  }
`;

export const FormContainer = styled.form`
  width: 50%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  ${MEDIA} {
    width: 100%;
  }
`;

export const FormTitle = styled.h1`
  color: white;
  font-size: 60px;

  ${MEDIA} {
    font-size: 35px;
  }
`;

export const FormDescription = styled.p`
  color: white;
  font-size: 20px;
  margin-bottom: 50px;
  text-align: start;

  ${MEDIA} {
    font-size: 16px;
    width: 80%;
    text-align: center;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  border-radius: 25px;
  border: none;
  padding: 0 30px;
  font-size: 20px;

  ${MEDIA} {
    width: 90%;
    font-size: 16px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 250px;
  padding: 5px 30px;
  font-size: 20px;
  border-radius: 25px;

  ${MEDIA} {
    width: 90%;
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  ${MEDIA} {
    padding: 0;
    height: 100px;
  }
`;

export const SubmitButton = styled.button`
  border: none;
  background-color: white;
  color: #c90225;
  width: 110px;
  height: 60px;
  border-radius: 30px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 50px;

  &:hover {
    cursor: pointer;
    background-color: #ccc;
  }

  ${MEDIA} {
    margin-top: 0;
  }
`;

export const Side = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${MEDIA} {
    width: 100%;
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  ${MEDIA} {
    margin-top: 30px;
  }
`;

export const Address = styled.p`
  width: 500px;
  color: white;
  font-size: 22px;
  margin-top: 30px;

  ${MEDIA} {
    width: 90%;
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const Map = styled.iframe`
  width: 500px;
  height: 350px;

  ${MEDIA} {
    width: 100%;
  }
`;

export const Comunication = styled.div`
  color: white;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 40px;
`;

export const ComunicationTitle = styled.h2`
  font-size: 40px;

  ${MEDIA} {
    font-size: 25px;
  }
`;

export const Phones = styled.div`
  display: flex;
  font-size: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${MEDIA} {
    font-size: 20px;
  }
`;

export const PContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Phone = styled(AiFillPhone)`
  margin-right: 10px;
`;

export const SocialMedia = styled.div`
  display: flex;
  font-size: 25px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px;
`;

export const SContainer = styled.a`
  text-decoration: none;
  color: #c90225;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  font-size: 26px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  min-width: 370px;

  &:hover {
    cursor: pointer;
    filter: brightness(70%);
  }

  ${MEDIA} {
    min-width: 100%;
    font-size: 20px;
  }
`;

export const Facebook = styled(FaFacebookSquare)`
  margin-right: 10px;
`;

export const Instagram = styled(FaInstagramSquare)`
  margin-right: 10px;
`;

export const Whatsapp = styled(FaWhatsappSquare)`
  margin-right: 10px;
`;
